import React from 'react'
import styled from 'styled-components'
import { IconCircleCheck } from '@edulastic/icons'
import { accessibilityColors } from '@edulastic/colors'
import { CodieBanner } from '../../Login/components/CodieBanner'

export const LeftContainer = (props) => {
  const { currentPage, isDistrictSignup } = props
  return (
    <Container isDistrictSignup={isDistrictSignup}>
      <InfoContainer>
        {!currentPage.includes('studentsignup') && (
          <CodieBanner
            currentPage={currentPage}
            bgColor={accessibilityColors.banner.yellow.bg}
            borderColor={accessibilityColors.banner.yellow.border}
          />
        )}

        {currentPage.toLowerCase().includes('getstarted') ? (
          <>
            <GreetText>Hi there 👋</GreetText>
            <WelcomeText>
              <StyledParagraph>Welcome to</StyledParagraph>
              <StyledParagraph>Pear Assessment!</StyledParagraph>
            </WelcomeText>
          </>
        ) : (
          <>
            <HeaderText>
              <StyledParagraph>Sign Up for a Free</StyledParagraph>
              <StyledParagraph>Pear Assessment Account</StyledParagraph>
            </HeaderText>
            {!currentPage.toLowerCase().includes('studentsignup') && (
              <FeatureList>
                <FeatureElement>
                  <IconCircleCheck checkColor="#185F40" width="24" /> Easily
                  create standards-aligned assessments with our content library.
                </FeatureElement>
                <FeatureElement>
                  <IconCircleCheck checkColor="#185F40" width="24" /> Save time
                  with auto-graded work.
                </FeatureElement>
                <FeatureElement>
                  <IconCircleCheck checkColor="#185F40" width="24" /> Make
                  data-informed decisions about your instructions in real-time.
                </FeatureElement>
                <FeatureElement>
                  <IconCircleCheck checkColor="#185F40" width="24" /> Choose
                  from over 50 question formats to keep students engaged.
                </FeatureElement>
              </FeatureList>
            )}
          </>
        )}
      </InfoContainer>
    </Container>
  )
}

const Container = styled.div`
  background: ${({ isDistrictSignup }) =>
    isDistrictSignup
      ? 'none'
      : "url('https://cdnedupoc.snapwiz.net/JS/assets/signUp-background-image.png')"};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  min-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  position: relative;
`

const InfoContainer = styled.div`
  position: absolute;
  top: 45%;
  left: 12%;
  transform: translateY(-50%);
  height: fit-content;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const GreetText = styled.p`
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  color: #eee;
  background-color: #195f40;
`

const WelcomeText = styled.p`
  font-size: 40px;
  font-weight: 700;
  line-height: 50.4px;
  margin: 15px 0px;
  color: White;
  background-color: #195f40;
`

const HeaderText = styled(WelcomeText)`
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  margin: 15px 0px;
  color: White;
  background-color: #195f40;
`

const StyledParagraph = styled.p`
  margin-bottom: 10px;
`

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #195f40;
`

const FeatureElement = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
`
