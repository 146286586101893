export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]
export const APP_NAME = 'Edulastic'
export const QUESTION_OPTIONS = 'QUESTION_OPTIONS'
export const QUESTION_PROBLEM = 'QUESTION_PROBLEM'
export const QUESTION_ANSWERS = 'QUESTION_ANSWERS'
export const QUESTION_USERSELECTIONS = 'QUESTION_USERSELECTIONS'
export const ASSESSMENTID = 'AssessmentId'
export const LARGE_DESKTOP_WIDTH = 1600
export const MEDIUM_DESKTOP_WIDTH = 1300
export const SMALL_DESKTOP_WIDTH = 993
export const NORMAL_MONITOR_WIDTH = 1366
export const MAX_XGA_WIDTH = 1024
export const MAX_TAB_WIDTH = 768
export const MAX_MOBILE_WIDTH = 468

export const DATA_STUDIO_DISABLED_DISTRICTS = [
  '635918f1559a0f00082f0694',
  '64929037eec3b3a15fb8dac8',
  '631f286ca526d5198f1eba80',
]

export const SWITCH_ACCOUNT_TUTORIAL_STEP = [
  {
    target: '[data-joyride-id="switchUserNew"]',
    title: 'Manage Your Accounts Easily',
    content:
      'You can now see the total number of connected accounts in your switch account menu.',
    placement: 'right',
    disableBeacon: true,
  },
]
export const SWITCH_ACCOUNT_TUTORIAL_STEP_ADD_ACCOUNT_USER = [
  {
    target: '[data-joyride-id="switchUserNew"]',
    title: 'New Account Successfully Linked',
    content: 'A new account has been added to your switch account menu.',
    placement: 'right',
    disableBeacon: true,
  },
]

export const TEACHER_COMMON_TUTORIAL_STEP = [
  {
    target: '[data-joyride-id="manageDistrict"]',
    title: 'New Common Test Setting',
    content:
      'You can now let selected teachers create common test, without admin intervention.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '[data-joyride-id="teacherCommonSetting"]',
    title: 'New Common Test Setting',
    content:
      'Selecting ‘Yes’ allows teachers to choose common test types. Admins can manage which teachers can publish district and school common tests.',
    placement: 'right',
    disableBeacon: true,
  },
]

export const ITEM_DETAILS_CHANGE_QUESTION_TYPE_TUTORIAL_STEPS = [
  {
    target: '[data-joyride-id="changeQuestionType"]',
    title: 'Change Item Type',
    content:
      'You can switch this item to a different item type. The question, standards, and rubric will carry over.',
    placement: 'bottom-start',
    disableBeacon: true,
  },
]
